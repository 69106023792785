/* Markdown container for dark mode */
.markdown-body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #e0e0e0;
  background-color: #1e1e2e;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}

/* Headings */
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  color: #ffffff;
}

.markdown-body h1 {
  font-size: 2.5em;
  color: #ffffff;
}

.markdown-body h2 {
  font-size: 2em;
  border-bottom: 2px solid #5dade2;
  padding-bottom: 4px;
}

.markdown-body h3 {
  font-size: 1.75em;
  color: #5dade2;
}

.markdown-body h4 {
  font-size: 1.5em;
}

.markdown-body h5 {
  font-size: 1.25em;
}

.markdown-body h6 {
  font-size: 1em;
}

/* Paragraphs */
.markdown-body p {
  margin-bottom: 1em;
  color: #e0e0e0;
}

/* Links */
.markdown-body a {
  color: #3498db;
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

/* Lists */
.markdown-body ul,
.markdown-body ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.markdown-body li {
  margin-bottom: 0.5em;
}

/* Blockquotes */
.markdown-body blockquote {
  border-left: 4px solid #5dade2;
  padding-left: 1em;
  color: #a0aec0;
  margin: 1em 0;
  background-color: #2e2e3e;
}

/* Code */
.markdown-body code {
  background-color: #2e2e3e;
  color: #f78c6c;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: Consolas, "Courier New", Courier, monospace;
}

.markdown-body pre {
  background-color: #2e2e3e;
  padding: 1em;
  overflow: auto;
  border-radius: 5px;
  font-family: Consolas, "Courier New", Courier, monospace;
  color: #e0e0e0;
}

/* Tables */
.markdown-body table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
  color: #e0e0e0;
}

.markdown-body th,
.markdown-body td {
  padding: 0.5em 1em;
  border: 1px solid #444;
}

.markdown-body th {
  background-color: #333;
  font-weight: bold;
}

/* Images */
.markdown-body img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em 0;
}

/* Additional Tips and Warnings */
.markdown-body .tip {
  background-color: #243447;
  border-left: 4px solid #3498db;
  padding: 12px;
  margin: 20px 0;
  color: #ffffff;
}

.markdown-body .warning {
  background-color: #512222;
  border-left: 4px solid #e74c3c;
  padding: 12px;
  margin: 20px 0;
  color: #ffcccc;
}

/* Horizontal Rule */
.markdown-body hr {
  border: 1px solid #444;
  margin: 20px 0;
}

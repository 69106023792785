/* 기본 스타일 */
body {
  font-family: "Arial", sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3 {
  color: white;
  text-align: center;
}

p {
  color: white;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  /* background-color: #007bff; */
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
}

/* 입력 필드 */
input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* 카드 레이아웃 */
.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.card h2 {
  margin-bottom: 15px;
}

/* 에러 메시지 */
.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 10px;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
}

.rbc-toolbar button {
  color: #ffffff !important;
  background-color: #1f2937 !important;
  border: 1px solid #4b5563 !important;
}

.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
  background-color: #4b5563 !important;
  color: #e5e7eb !important;
}
